class SylndrStorage {
    private static instance: SylndrStorage;

    public static getInstance(): SylndrStorage {
        if (!SylndrStorage.instance) {
            SylndrStorage.instance = new SylndrStorage();
        }
        return SylndrStorage.instance;
    }
    getItem(key: string) {
        return localStorage.getItem(key);
    }
    setItem(key: string, value: string) {
        return localStorage.setItem(key, value);
    }
    deleteItem(key: string) {
        return localStorage.removeItem(key);
    }
}

export const authStorage = SylndrStorage.getInstance();
