'use client';
import {
    FeatureDefinition,
    GrowthBook,
    GrowthBookProvider,
} from '@growthbook/growthbook-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { AppFeatures } from '@/utils/growthbook';
import {
    GA4_MEASUREMENT_ID,
    NEXT_PUBLIC_GROWTHBOOK_API_HOST,
    NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
} from '@/constants';
import { useAnalytics } from '@/integrations';

interface Props {
    attributes?: Record<string, any>;
    children: ReactNode;
    features?: Record<string, FeatureDefinition<any>>;
}

export default function GrowthbookClientProvider({
    children,
    features,
}: Props) {
    const { trackEvent } = useAnalytics();
    const [growthbook, setGrowthBook] = useState(null);
    const [userInformation, setUserInformation] = useState([null, null]);
    const [triesToReadInfo, setTriesToReadInfo] = useState(0);
    const maxTriesToReadUserData = 10;

    useEffect(() => {
        function getGA4UserId() {
            const reg = new RegExp('_ga=GA(.+?)(?:;|$)');
            const parts = (document.cookie.match(reg) || ['', ''])[1].split(
                '.'
            );
            if (!parts) {
                return null;
            }
            return parts.slice(-2).join('.');
        }
        function getGA4SessionId() {
            const reg = new RegExp(
                `_ga_${GA4_MEASUREMENT_ID}=GS\\d\\.\\d\\.(.+?)(?:;|$)`
            );
            const match = document.cookie.match(reg);
            const parts = match?.[1].split('.');
            if (!parts) {
                return null;
            }
            return parts[0];
        }
        let readTimeout = null;
        if (!userInformation[0] && triesToReadInfo <= maxTriesToReadUserData) {
            const readedUserInfo = [getGA4UserId(), getGA4SessionId()];
            if (!readedUserInfo[0]) {
                readTimeout = setTimeout(() => {
                    setTriesToReadInfo(triesToReadInfo + 1);
                }, 1250);
            } else {
                setUserInformation(readedUserInfo);
            }
        }
        return () => {
            if (readTimeout) {
                clearTimeout(readTimeout);
            }
        };
    }, [triesToReadInfo]);

    useEffect(() => {
        if (userInformation[0]) {
            const gb = new GrowthBook<AppFeatures>({
                apiHost: NEXT_PUBLIC_GROWTHBOOK_API_HOST,
                clientKey: NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
                attributes: {
                    // ...attributes,
                    id: userInformation[0],
                    sid: userInformation[1],
                },
                features,
                trackingCallback: (experiment, result) => {
                    trackEvent('experiment_viewed', {
                        event_category: 'experiment',
                        experiment_id: experiment.key,
                        variation_id: result.variationId,
                        uid: `${userInformation[0]}`,
                        sid: `${userInformation[1]}`,
                    });
                },
            });
            setGrowthBook(gb);
        }
    }, [userInformation]);

    useEffect(() => {
        if (growthbook) {
            growthbook.loadFeatures({ timeout: 10200 });
        }
    }, [growthbook]);

    return (
        <GrowthBookProvider growthbook={growthbook}>
            <>{children}</>
        </GrowthBookProvider>
    );
}
