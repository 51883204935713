'use client';
import { APP_ENV, userIdKey } from '@/constants';
import { authStorage } from '@/classes/SylndrStorage';

const trackEvent = (name: string, body?: Record<string, any>) => {
    setTimeout(() => {
        if (typeof window === 'undefined') return;
        //@ts-ignore
        const userId = authStorage.getItem(userIdKey);
        if (userId && userId.length) {
            if (body) {
                body.userId = userId;
            } else {
                body = { userId };
            }
        }

        window.dataLayer = window.dataLayer || [];
        if (window.dataLayer.length >= 3) {
            const event = { event: name, ...body };
            //Enable track event logging on production.
            //window.localStorage.setItem('sTrOn',1)
            if (
                APP_ENV !== 'production' ||
                (window &&
                    window.localStorage &&
                    window.localStorage.getItem('sTrOn'))
            ) {
                // eslint-disable-next-line no-console
                console.log('🚀 Tracked Event: ', event);
            }
            //@ts-ignore
            window.dataLayer.push(event);
            try {
                window.webengage.track(name, {
                    ...body,
                    date: new Date(),
                    language: authStorage.getItem('i18nextLng') ?? 'ar',
                });
            } catch (e) {
                // nothing.
            }

            return event;
        } else {
            setTimeout(() => {
                trackEvent(name, body);
            }, 200);
        }
    }, 0);
};

export const useAnalytics = () => {
    return {
        trackEvent,
    };
};
